import { myCourses } from 'API/course'
import { Col, Row, Card, Container, Figure, Jumbotron } from 'react-bootstrap'
import React, { Fragment } from 'react'
import { useAppSelector } from 'redux/hooks'
import { CourseType } from 'types/CourseAdditionMetaData'
import { NonUndefined, PromiseType, ValuesType } from 'utility-types'
import { Link } from 'gatsby'
import { getTracking } from 'API/user'
import { RepoMap } from 'components/RepoMap'

export type RetType = PromiseType<ReturnType<typeof myCourses>>
export const Course: React.FC<{
	course: CourseType
	tracking_1?: ValuesType<NonUndefined<PromiseType<ReturnType<typeof getTracking>>>>
	tracking_0?: ValuesType<NonUndefined<PromiseType<ReturnType<typeof getTracking>>>>
}> = ({ course, tracking_1, tracking_0 }) => {
	const search = useAppSelector((state) => state.ApplicationReducer.search)
	if (search !== '' && search.split('=')?.[1]?.toUpperCase() !== course.course_tag.toUpperCase()) {
		return <></>
	}
	return (
		<Fragment>
			<Col lg={3} md={3}>
				<Link to={`/course/${course.uid}`}>
					<Card className='classCard-2'>
						<Container className='mt-2 mb-2'>
							<Row className='align-items-center '>
								<Col xs='10'>
									<h6 className='subName text-light'>{course.name}</h6>
								</Col>

								{/* <Col xs={2}></Col> */}
							</Row>
						</Container>
					</Card>
				</Link>
			</Col>

			{/* <div className='internal-card row mt-4 mx-2'>
				<div className='card accordion'>
					<div className='card-body'>
						<h5 className='card-title'>{course.name}</h5>
						<div className=' card-text'>
							<div className='row'>
								<div className='col'>
									<p className=''>Description: {course.description}</p>
								</div>
								<div className='col'>
									<div className='row'>
										<p>From:{new Date(course.start_date).toDateString()}</p>
									</div>
									<div className='row'>
										<p>To:{new Date(course.end_date).toDateString()}</p>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col'>
									Course Type: <span className='bold'>{course.course_tag}</span>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col'>
								<Link to={`/course/${course.uid}`} className='btn btn-primary '>
									View Course
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
	 */}
		</Fragment>
	)
}
